.background {
  background-image: url('../../../../assets/images/auth/bg-overlay-large.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* For screens between smaller than 600px in height */
@media (max-height: 640px) {
  .background {
    background-image: url('../../../../assets/images/auth/bg-overlay-small.svg');
  }
}

/* For screens between 600px and 825px in height */
@media (min-height: 640px) and (max-height: 825px) {
  .background {
    background-image: url('../../../../assets/images/auth/bg-overlay-medium.svg');
  }
}

/* For screens larger than 825px in height */
@media (min-height: 825px) {
  .background {
    background-image: url('../../../../assets/images/auth/bg-overlay-large.svg');
  }
}
