/* ---- remove backgrounds ---- */

/* main calendar background */
.calendar-weekly-container
  .rbc-calendar
  *:not(.calendar-event-card-container):not(
    .calendar-event-card-container *
  ):not(.event-card-blocked) {
  background: transparent;
}

/* ---- get rid of borders ---- */

/* header with time labels */
.calendar-weekly-container .rbc-time-header * {
  border: none;
  border-left: none !important;
  border-right: none !important;
}

.calendar-weekly-container .rbc-time-header.rbc-overflowing {
  border-right: none !important;
}

/* the time view container */
.calendar-weekly-container .rbc-time-view {
  border: none;
}

.calendar-weekly-container .rbc-time-content {
  border-top: none;
  padding-top: 20px;
}

/* custom class for date labels */
.calendar-weekly-container .date-label-number {
  border: 1px solid #d7c0fb !important;
}

/* each time slot cell */
.calendar-weekly-container .rbc-time-slot {
  border: none !important;
}

/* left side time label borders */
.calendar-weekly-container .rbc-time-gutter .rbc-timeslot-group {
  border: none !important;
}

/* first time slot column left borders */
.calendar-weekly-container .rbc-day-slot:nth-child(2) * {
  border-left: none;
}

/* time slot columns bottom borders */
.rbc-timeslot-group:nth-last-child(2),
.calendar-weekly-container .rbc-today .rbc-timeslot-group:nth-last-child(3) {
  border-bottom: none;
}

/* set border colors */
.calendar-weekly-container .rbc-calendar *:not(.calendar-card-left-border) {
  border-color: rgba(255, 255, 255, 0.2);
}

/* set border to top most row */
.calendar-weekly-container .rbc-timeslot-group:first-child {
  border-top-width: 1px;
}

.calendar-weekly-container .rbc-timeslot-group:last-child {
  border-bottom-width: 1px;
}

/* ---- left side time label ---- */

/* set width of time label column */
.calendar-weekly-container .rbc-time-gutter {
  display: inline-block;
  width: 50px;
}

/* shift label to align with time slot borders */
.calendar-weekly-container .rbc-time-gutter .rbc-timeslot-group {
  position: relative;
  overflow: visible;
  font-family: 'Sofia Pro Regular';
}

.calendar-weekly-container .rbc-time-gutter .rbc-time-slot .rbc-label {
  /* background: white !important;  */
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ---- time indicator ---- */
.calendar-weekly-container .rbc-current-time-indicator {
  border: 1px solid white !important;
}

/* ---- header with time labels ---- */

/* whole header container */
.calendar-weekly-container .rbc-time-header-content {
  margin-bottom: 22px;
  height: auto;
}

/* header part with time labels */
.calendar-weekly-container .rbc-time-header-cell {
  /* background:white; */
  padding: 0px;
}

/* all day event container*/
.calendar-weekly-container .rbc-allday-cell {
  display: none;
}

/* today label */
.calendar-weekly-container .date-label-number-today {
  background: #ebdfff !important;
  border-color: #ebdfff !important;
  color: #6000e9;
}

/* selected label */
.calendar-weekly-container .date-label-number-selected {
  background: #6000e9 !important;
  border-color: #6000e9 !important;
  color: white;
}

/* event styling */

.calendar-weekly-container .rbc-event {
  /* padding: 8px;
    font-size: 12px;
    background-color: aqua;*/

  border-radius: 0px !important;
  background-color: transparent !important;
  padding: 0px !important;
  border: none !important;
}

.calendar-weekly-container .rbc-background-event {
  /* background-color: #3b0606 !important; */
  border: none;
  padding: 0px !important;
}

.calendar-weekly-container .rbc-events-container {
  padding: 0px;
  background: transparent;
  width: 100%;
}

.calendar-weekly-container .rbc-event-label {
  display: none;
}

.calendar-weekly-container .rbc-time-slot {
  /* background: #eaf3fa; */
  color: #74a4c3;
}

.calendar-weekly-container .rbc-event.rbc-selected {
  outline: none !important;
}

/* hide time selector label */
.calendar-weekly-container .rbc-slot-selection {
  display: none;
}

/* blocked card  background */
.calendar-weekly-container .event-card-blocked:not(p) {
  background: repeating-linear-gradient(
    -45deg,
    /* Angle of the lines */ rgba(211, 211, 211, 0.1),
    /* Semi-transparent light gray for the lines */ rgba(211, 211, 211, 0.2) 1px,
    /* Thickness of the line */ transparent 2px,
    /* Space after the line */ transparent 10px
      /* Total space before the next line */
  );
}
